export const STATUS_NEW = 1;
export const STATUS_PROCEEDING = 2;
export const STATUS_VALIDATED = 3;
export const STATUS_VALIDATION_FAILED = 4;
export const STATUS_DELETED = 5;
export const STATUS_UNPAID = 6;

export const SSL_STATUS_NOT = 0;
export const SSL_STATUS_IN_PROGRESS = 1;
export const SSL_STATUS_REQUEST_FAILED = 2;
export const SSL_STATUS_ACTIVE = 3;
export const SSL_STATUS_INACTIVE = 4;

export const TYPE_INTERNAL = 1;
export const TYPE_EXTERNAL = 2;

export const DOMAIN_STATUS_LABELS = {
  [STATUS_NEW]: 'New',
  [STATUS_PROCEEDING]: 'Proceeding',
  [STATUS_VALIDATED]: 'Validated',
  [STATUS_VALIDATION_FAILED]: 'Validation failed',
  [STATUS_DELETED]: 'Deleted',
  [STATUS_UNPAID]: 'Unpaid',
};

export const SSL_STATUS_LABELS = {
  [SSL_STATUS_NOT]: 'Not',
  [SSL_STATUS_IN_PROGRESS]: 'In Progress',
  [SSL_STATUS_REQUEST_FAILED]: 'Request failed',
  [SSL_STATUS_ACTIVE]: 'Active',
  [SSL_STATUS_INACTIVE]: 'Inactive',
};
