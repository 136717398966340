import { ACTIONS, SUBJECTS } from '@/enums/permissions';

export default [
  {
    path: 'email-autoresponders/',
    name: 'email-autoresponders',
    meta: {
      checkAccess: [{
        action: ACTIONS.view,
        subject: SUBJECTS.UserAutoresponder,
      }],
    },
    component: () => import(/* webpackChunkName: "emailAutoresponders" */ '@/pages/settings/setup/EmailAutoresponders/List.vue'),
  },
  {
    path: 'email-autoresponders/add',
    name: 'email-autoresponder-add',
    meta: {
      checkAccess: [{
        action: ACTIONS.create,
        subject: SUBJECTS.UserAutoresponder,
      }],
    },
    component: () => import(/* webpackChunkName: "emailAutoresponders" */ '@/pages/settings/setup/EmailAutoresponders/Edit.vue'),
  },
  {
    path: 'email-autoresponders/:id/edit',
    name: 'email-autoresponder-edit',
    props: true,
    meta: {
      checkAccess: [{
        action: ACTIONS.edit,
        subject: SUBJECTS.UserAutoresponder,
      }],
    },
    component: () => import(/* webpackChunkName: "emailAutoresponders" */ '@/pages/settings/setup/EmailAutoresponders/Edit.vue'),
  },
  {
    path: 'email-autoresponders/:id',
    redirect: { name: 'email-autoresponder-edit' },
  },
];
