import Api from '@/api';

export const SET_LIST = 'setList';
export const SET_SINGLE = 'setSingle';
export const UNSET_SINGLE = 'unsetSingle';

export default {
  namespaced: true,

  state: {
    list: [],
    single: null,
  },

  actions: {
    async getList({ commit }, params) {
      const data = await Api.Trackings.getList(params);
      commit(SET_LIST, data);
    },
    async getOne({ commit }, { id, params }) {
      const data = await Api.Trackings.getOne(id, params);
      commit(SET_SINGLE, data);
    },
    async update({ commit }, { id, body }) {
      const data = await Api.Trackings.update(id, body);
      commit(SET_SINGLE, data);
      return data;
    },
    async create({ commit }, body) {
      const data = await Api.Trackings.create(body);
      commit(SET_SINGLE, data);
      return data;
    },
    async delete({ commit }, id) {
      const data = await Api.Trackings.delete(id);
      commit(SET_SINGLE, data);
    },
  },

  mutations: {
    [SET_SINGLE](state, payload) {
      state.single = payload;
    },
    [UNSET_SINGLE](state) {
      state.single = null;
    },
    [SET_LIST](state, payload) {
      state.list = payload;
    },
  },
};
