import injector from 'vue-inject';
import Vue from 'vue';
import axios from 'axios';

import './apiUser';
import './apiUserAxios';
import './localSettings';

injector.constant('axios', axios);
Vue.use(injector);

export default injector;
