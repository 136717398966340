const size = [
  '12px',
  '13px',
  '14px',
  '15px',
  '16px',
  '18px',
  '22px',
  '24px',
];

export default [
  [{
    header: [false, 1, 2, 3, 4, 5, 6],
  }],
  [{
    size,
  }],
  [{
    color: [],
  }],
  ['bold', 'italic', 'underline', 'strike'],
  [{
    align: '',
  },
  {
    align: 'center',
  },
  {
    align: 'right',
  },
  {
    align: 'justify',
  },
  ],
  [{
    list: 'bullet',
  },
  'link',
  'video',
  ],
];
