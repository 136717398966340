import { ACTIONS, SUBJECTS } from '@/enums/permissions';

export default [
  {
    path: 'payment-gateways/',
    name: 'payment-gateways',
    meta: {
      checkAccess: [{
        action: ACTIONS.view,
        subject: SUBJECTS.UserPayment,
      }],
    },
    component: () => import(/* webpackChunkName: "paymentGateways" */ '@/pages/settings/setup/PaymentGateways/List.vue'),
  },
  {
    path: 'payment-gateways/:id/edit',
    name: 'payment-gateways-edit',
    props: true,
    meta: {
      checkAccess: [{
        action: ACTIONS.edit,
        subject: SUBJECTS.UserPayment,
      }],
    },
    component: () => import(/* webpackChunkName: "paymentGateways" */ '@/pages/settings/setup/PaymentGateways/Edit.vue'),
  },
  {
    path: 'payment-gateways/add',
    name: 'payment-gateways-add',
    meta: {
      checkAccess: [{
        action: ACTIONS.create,
        subject: SUBJECTS.UserPayment,
      }],
    },
    component: () => import(/* webpackChunkName: "paymentGateways" */ '@/pages/settings/setup/PaymentGateways/Edit.vue'),
  },
];
