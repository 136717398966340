import { URL_API_USER } from '@dmant/ez-env-common';
import Api from './Api';

class TrackingsApi extends Api {
  constructor() {
    super({
      baseURL: URL_API_USER,
      prefix: '/v1/tracking',
    });
  }

  getList(ownerId) {
    const params = {
      'filter[payment][and][user][eq]': ownerId,
      'fields[payment]': 'id,name,active',
      size: 500,
    };

    return super.getList(params);
  }
}

export default new TrackingsApi();
