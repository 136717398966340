export default {
  path: '',
  redirect: '/settings/account',
  meta: {
    hideLiveChat: true,
    login: true,
  },
  component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/auth.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/login.vue'),
    },
    {
      path: 'restore',
      name: 'restore',
      component: () => import(/* webpackChunkName: "auth-restore" */ '../pages/auth/passwordRestore.vue'),
    },
    {
      path: 'restore-success',
      name: 'restore-success',
      component: () => import(/* webpackChunkName: "auth-restore" */ '../pages/auth/passwordRestoreSuccess.vue'),
    },
    {
      path: 'restore-verification',
      name: 'restore-verification',
      component: () => import(/* webpackChunkName: "auth-restore" */ '../pages/auth/passwordRestoreVerification.vue'),
    },
    {
      path: 'changepass',
      name: 'changepass',
      component: () => import(/* webpackChunkName: "auth-restore" */ '../pages/auth/changepass.vue'),
    },
  ],
};
