import axios from 'axios';
import store from '@/store';

const createAxiosInstance = ({ baseURL }) => {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.response.use((result) => {
    const { data } = result.data;
    return data || result.data;
  }, (error) => {
    const { status } = error.response || {};
    if (status === 403) {
      store.commit('auth/SET_EXPIRED');
    }
    return Promise.reject(error);
  });

  return axiosInstance;
};

export default createAxiosInstance;
