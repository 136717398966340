export default [
  {
    name: 'Klick-Tipp',
    urlName: 'klick_tipp',
    fields: ['user', 'password'],
    text: 'Klick-Tipp explanations',
  },
  {
    name: 'Active Campaign',
    urlName: 'active_campagin',
    fields: ['api_key', 'api_url'],
    text: 'Active Campaign explanations',
  },
  {
    name: 'Quentn',
    urlName: 'quentin',
    fields: ['api_key', 'api_url'],
    text: 'Quentn explanations',
  },
  {
    name: 'Mailchimp',
    urlName: 'mail_chimp',
    fields: ['api_key'],
    text: 'Mailchimp explanations',
  },
  {
    name: 'GetResponse',
    urlName: 'get_response',
    fields: ['api_key'],
    text: 'GetResponse explanations',
  },
  {
    name: '4Leads',
    urlName: '4leads',
    fields: ['api_key'],
    text: '4Leads explanations',
  },
];
