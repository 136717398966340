export const EN = 'en';
export const DE = 'de';

export const mapLocales = {
  en_EN: EN,
  en: EN,

  de_DE: DE,
  de: DE,
};

export default {
  enEN: 'en_EN',
  deDE: 'de_DE',
};
