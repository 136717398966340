import { ACTIONS, SUBJECTS } from '@/enums/permissions';

export const TRACKINGS = {
  base: 'trackings.base',
  index: 'trackings.index',
  list: 'trackings.list',
  edit: 'trackings.edit',
  create: 'trackings.create',
};

const routes = [
  {
    path: 'trackings',
    name: TRACKINGS.base,
    redirect: { name: TRACKINGS.index },
    props: true,
    component: () => import(/* webpackChunkName: "trackings" */ '../../pages/settings/setup/Trackings/Index'),
    children: [
      {
        path: '',
        name: TRACKINGS.index,
        props: true,
        meta: {
          checkAccess: [{
            action: ACTIONS.view,
            subject: SUBJECTS.UserTracking,
          }],
        },
        component: () => import(/* webpackChunkName: "trackings" */ '../../pages/settings/setup/Trackings/List'),
      },
      {
        path: ':id/edit',
        name: TRACKINGS.edit,
        props: true,
        meta: {
          checkAccess: [{
            action: ACTIONS.edit,
            subject: SUBJECTS.UserTracking,
          }],
        },
        component: () => import(/* webpackChunkName: "trackings" */ '../../pages/settings/setup/Trackings/Edit'),
      },
      {
        path: 'add',
        name: TRACKINGS.create,
        props: true,
        meta: {
          checkAccess: [{
            action: ACTIONS.create,
            subject: SUBJECTS.UserTracking,
          }],
        },
        component: () => import(/* webpackChunkName: "trackings" */ '../../pages/settings/setup/Trackings/Edit'),
      },
    ],
  },
];

export default routes;
