import { URL_API_USER } from '@dmant/ez-env-common';
import Api from './Api';

class EmailForwardingApi extends Api {
  constructor() {
    super({
      baseURL: URL_API_USER,
      prefix: '/v1/email-forwarding',
    });
  }

  getByDomainId(domainId) {
    return this.custom('get', '', { 'filter[domain][eq]': domainId });
  }

  create(form, domainId) {
    const data = this.prepareRequestBody(form, domainId);
    return super.create({ data });
  }

  update(emailForwardingId, form, domainId) {
    const data = this.prepareRequestBody(form, domainId);
    return super.update(emailForwardingId, { data });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareRequestBody(form, domainId) {
    return {
      attributes: form,
      relationships: {
        domain: {
          data: {
            type: 'domain',
            id: domainId,
          },
        },
      },
      type: 'email-forwarding',
    };
  }
}

export default new EmailForwardingApi();
