export const SUBJECTS = {
  UserAutoresponder: 'UserAutoresponder',
  UserBilling: 'UserBilling',
  UserDomain: 'UserDomain',
  UserImprint: 'UserImprint',
  UserManagers: 'UserManagers',
  UserPayment: 'UserPayment',
  UserPrivacyPolicy: 'UserPrivacyPolicy',
  UserSmtp: 'UserSmtp',
  UserTracking: 'UserTracking',
  BuilderTemplates: 'BuilderTemplates',
  ContactsCustomerTypeDivisions: 'ContactsCustomerTypeDivisions',
};

export const ACTIONS = {
  create: 'create',
  view: 'view',
  edit: 'edit',
  delete: 'delete',
};
