import { ACTIONS, SUBJECTS } from '@/enums/permissions';

export default [{
  path: 'domains',
  name: 'domains',
  props: true,
  component: () => import(/* webpackChunkName: "domains" */ '@/pages/settings/setup/Domains/DomainsIndex.vue'),
  children: [
    {
      path: '',
      name: 'domain-list',
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.UserDomain,
        }],
      },
      component: () => import(/* webpackChunkName: "domains" */ '@/pages/settings/setup/Domains/List.vue'),
    },
    {
      path: ':id/edit',
      name: 'domain-edit',
      props: true,
      meta: {
        checkAccess: [{
          action: ACTIONS.edit,
          subject: SUBJECTS.UserDomain,
        }],
      },
      component: () => import(/* webpackChunkName: "domains" */ '@/pages/settings/setup/Domains/Edit.vue'),
    },
    {
      path: 'add',
      meta: {
        checkAccess: [{
          action: ACTIONS.create,
          subject: SUBJECTS.UserDomain,
        }],
      },
      component: () => import(/* webpackChunkName: "domain-add" */ '@/pages/settings/setup/Domains/Add/AddDomainIndex.vue'),
      children: [
        {
          path: '',
          name: 'domain-add',
          component: () => import(/* webpackChunkName: "domain-add" */ '@/pages/settings/setup/Domains/Add/Add.vue'),
        },
        {
          path: 'existing',
          name: 'domain-add-existing',
          component: () => import(/* webpackChunkName: "domains" */ '@/pages/settings/setup/Domains/Edit.vue'),
        },
        {
          path: 'buying',
          name: 'domain-buying',
          props: true,
          component: () => import(/* webpackChunkName: "domain-add" */ '@/pages/settings/setup/Domains/Add/DomainBuying.vue'),
        },
        {
          path: 'search',
          name: 'domain-search',
          component: () => import(/* webpackChunkName: "domain-add" */ '@/pages/settings/setup/Domains/Add/DomainSearch.vue'),
        },
        {
          path: 'selected',
          name: 'domain-selected',
          props: true,
          component: () => import(/* webpackChunkName: "domain-add" */ '@/pages/settings/setup/Domains/Add/DomainSelected.vue'),
        },
      ],
    },
    {
      path: 'thanks',
      name: 'domain-thanks',
      props: true,
      component: () => import(/* webpackChunkName: "domains-add" */ '@/pages/settings/setup/Domains/Add/DomainThanks.vue'),
      beforeEnter: async (to, from, next) => {
        if (to.query.custom) {
          const params = to.query.custom.split('_');
          if (params.length > 2 && params[2] === 'wizard') {
            next({
              name: 'wizardDomains',
              params: {
                domainId: params[1],
              },
            });
          }
          next();
        } else next();
      },
    },
  ],
}];
