import { URL_API_USER } from '@dmant/ez-env-common';
import { DOMAIN_STATUSES } from '@/enums';
import Api from './Api';

class DomainsApi extends Api {
  constructor() {
    super({
      baseURL: URL_API_USER,
      prefix: '/v1/domain',
    });
  }

  getList(ownerId, parentDomainId) {
    const parentFilter = parentDomainId ? {
      filter: 'eq',
      value: parentDomainId,
    } : {
      filter: 'isNull',
      value: true,
    };

    const params = {
      'filter[domain][and][user][eq]': ownerId,
      [`filter[domain][and][parent][${parentFilter.filter}]`]: parentFilter.value,
      'filter[domain][and][status][neq]': DOMAIN_STATUSES.STATUS_DELETED,
      'page[size]': 500,
      checkCanDelete: 1,
      sort: 'dt-create',
    };
    return super.getList(params);
  }

  create(form, ownerId, parentDomainId) {
    const data = this.prepareRequestBody(form, ownerId, parentDomainId);
    return super.create({ data });
  }

  update(domainId, form, ownerId) {
    const data = this.prepareRequestBody(form, ownerId);
    return super.update(domainId, { data });
  }

  validate(id) {
    return this.custom('get', `${id}/validate`);
  }

  switchSsl(id, state) {
    const sslState = state ? 'enable-ssl' : 'disable-ssl';
    return this.custom('get', `${id}/${sslState}`);
  }

  createSsl(id) {
    return this.custom('get', `${id}/create-ssl`);
  }

  searchDomain(domain) {
    return this.custom('get', 'domain-search', { domain });
  }

  checkDomain(domain) {
    return this.custom('get', 'domain-check', { domain });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareRequestBody(form, ownerId, parentDomainId) {
    const parent = {
      data: {
        type: 'domain',
        id: parentDomainId,
      },
    };
    return {
      attributes: form,
      relationships: {
        user: {
          data: {
            type: 'user',
            id: ownerId,
          },
        },
        parent: parentDomainId ? parent : undefined,
      },
      type: 'domain',
    };
  }
}

export default new DomainsApi();
