import Api from '@/api';

export const SET_LIST = 'setList';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  actions: {
    async getList({ commit }, params) {
      const data = await Api.Funnels.getList(params);
      commit(SET_LIST, data);
    },
  },

  getters: {
    funnelsOptions: (state) => state.list,
    pages: (state) => state.list.map((item) => ({
      hint: item.attributes.name,
      href: item.id,
      id: item.id,
    })),
  },

  mutations: {
    [SET_LIST](state, payload) {
      state.list = payload;
    },
  },
};
