import { ACTIONS, SUBJECTS } from '@/enums/permissions';
import domains from './domains';
import emailAutoresponders from './emailAutoresponders';
import paymentGateways from './paymentGateways';
import trackingCodes from './trackingCodes';
import smtp from './smtp';

export default {
  path: '/settings',
  redirect: '/settings/account',
  component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/mainLayout.vue'),
  children: [
    ...domains,
    ...emailAutoresponders,
    ...paymentGateways,
    ...trackingCodes,
    ...smtp,
    {
      path: 'account/:userId?',
      name: 'account',
      component: () => import(/* webpackChunkName: "account" */ '@/pages/settings/general/account.vue'),
    },
    {
      path: 'billing/:userId?',
      name: 'billing',
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.UserBilling,
        }],
      },
      component: () => import(/* webpackChunkName: "billing" */ '@/pages/settings/general/billing.vue'),
    },
    {
      path: 'imprint/:userId?',
      name: 'imprint',
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.UserImprint,
        }],
      },
      component: () => import(/* webpackChunkName: "imprint" */ '@/pages/settings/general/imprint.vue'),
    },
    {
      path: 'privacy-policy/:userId?',
      name: 'privacyPolicy',
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.UserPrivacyPolicy,
        }],
      },
      component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/pages/settings/general/privacyPolicy.vue'),
    },
    {
      path: 'my-templates/:type?',
      name: 'myTemplates',
      props: true,
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.BuilderTemplates,
        }],
      },
      component: () => import(/* webpackChunkName: "myTemplates" */ '@/pages/settings/general/myTemplates.vue'),
      alias: 'my-templates',
    },
    {
      path: 'data-protection-contract/:userId?',
      name: 'dataProtectionContract',
      component: () => import(/* webpackChunkName: "dataProtectionContract" */ '@/pages/settings/general/dataProtectionContract.vue'),
    },
    {
      path: 'managers/:userId?',
      name: 'managers',
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.UserManagers,
        }],
      },
      component: () => import(/* webpackChunkName: "managers" */ '@/pages/settings/managers.vue'),
    },
    {
      path: 'customer-type-division/:userId?',
      name: 'customerTypeDivision',
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.ContactsCustomerTypeDivisions,
        }],
      },
      component: () => import(/* webpackChunkName: "customerTypeDivision" */ '@/pages/settings/customerTypeDivision.vue'),
    },
  ],
};
