import * as types from './template-types';

const keys = Object.keys(types);
const groups = keys.reduce((acc, item) => {
  if (item.indexOf('STEP_TYPE_') === 0) {
    acc.funnels.push(types[item]);
  } else if (item.indexOf('EMAIL_TYPE_') === 0) {
    acc.emails.push(types[item]);
  }
  return acc;
}, {
  funnels: [],
  emails: [],
});

export default groups;
