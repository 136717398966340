import Vue from 'vue';
import Vuex from 'vuex';

import i18n from '@/i18n';

import base from './baseModule';
import settings from './settingsModule';
import auth from './authModule';
import academies from './academies';
import funnels from './funnels';
import trackings from './trackings';
import wizard from './wizard';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    base,
    settings,
    auth,
    academies,
    funnels,
    trackings,
    wizard,
  },
  getters: {
    optionsForDefaultPage: (state, getters) => ([
      {
        label: 'Funnels',
        children: [
          {
            hint: i18n.t('No Default Page set'),
            id: null,
          },
          ...getters['funnels/pages'],
        ],
      },
      {
        label: 'Academies',
        children: getters['academies/pages'],
      },
    ]),
    optionsFor404Page: (state, getters) => ([
      {
        label: 'Funnels',
        children: [
          {
            hint: i18n.t('No 404 Page set'),
            id: null,
          },
          ...getters['funnels/pages'],
        ],
      },
      {
        label: 'Academies',
        children: getters['academies/pages'],
      },
    ]),
  },
});
