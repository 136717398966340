/* eslint-disable radix */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import {
  URL_API_BUILDER,
  URL_API_CLOUD,
  URL_API_CONTACTS,
  URL_API_FUNNELS,
  URL_API_USER,
} from '@dmant/ez-env-common';
import axios from 'axios';
import templateGroups from '@/enums/template-groups';

class ApiSettings {
  getAccountInfo(userId) {
    return axios.get(`${URL_API_USER}/v1/users/${userId}`, {
      withCredentials: true,
    });
  }

  setAccountInfo(userId, attributes) {
    return axios.patch(
      `${URL_API_USER}/v1/users/${userId}`,
      {
        data: {
          attributes,
          type: 'user',
        },
      },
      {
        withCredentials: true,
      },
    );
  }

  sendConfirmationEmail() {
    return axios.post(
      `${URL_API_USER}/v1/confirmation/resend`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  cancelConfirmationEmail() {
    return axios.delete(
      `${URL_API_USER}/v1/confirmation/delete`,
      {
        withCredentials: true,
      },
    );
  }

  confirmEmailChange(code) {
    return axios.post(
      `${URL_API_USER}/v1/change-email/${code}`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  changePassword(userId, newPasswordText) {
    // todo
  }

  getImprint(userId) {
    return axios.get(`${URL_API_USER}/v1/imprint?filter[user][eq]=${userId}`, {
      withCredentials: true,
    });
  }

  setImprint(text, userId) {
    return axios.post(
      `${URL_API_USER}/v1/imprint`,
      {
        data: {
          attributes: {
            imprint: text,
          },
          relationships: {
            user: {
              data: {
                type: 'user',
                id: userId,
              },
            },
          },
        },
      },
      {
        withCredentials: true,
      },
    );
  }

  getPrivacyPolicy(userId) {
    return axios.get(`${URL_API_USER}/v1/privacy-policy?filter[user][eq]=${userId}`, {
      withCredentials: true,
    });
  }

  setPrivacyPolicy(text, userId) {
    return axios.post(
      `${URL_API_USER}/v1/privacy-policy`,
      {
        data: {
          attributes: {
            'privacy-policy': text,
          },
          relationships: {
            user: {
              data: {
                type: 'user',
                id: userId,
              },
            },
          },
        },
      },
      {
        withCredentials: true,
      },
    );
  }

  getMyTemplates(userId, group) {
    const types = templateGroups[group] || [];
    const fields = [
      'name',
      'name-de',
      'name-en',
      'type',
      'preview-cloud',
      'preview-de-cloud',
      'preview-en-cloud',
    ];
    const params = {
      'filter[templates][and][personal][eq]': 1,
      'filter[templates][and][type][in]': types.join(','),
      'filter[templates][and][user][eq]': userId,
      'fields[templates]': fields.join(','),
    };
    return axios.get(`${URL_API_BUILDER}/v1/templates`, {
      params,
      withCredentials: true,
    });
  }

  deleteTemplate(templateId) {
    return axios.delete(`${URL_API_BUILDER}/v1/templates/${templateId}`, {
      withCredentials: true,
    });
  }

  getBillingInvoices(userId) {
    const query = `page[number]=1&page[size]=10&sort=id&filter[user][eq]=${userId}`;
    return axios.get(`${URL_API_USER}/v1/billing?${query}`, {
      withCredentials: true,
    });
  }

  getLimits(serviceId, userId) {
    return axios.get(`${URL_API_USER}/v1/services/${serviceId}/limits/${userId}`, {
      withCredentials: true,
    });
  }

  getTariff(userId) {
    return axios.get(`${URL_API_USER}/v1/tariff-current/${userId}`, {
      withCredentials: true,
    });
  }

  dataProtectionContractSignatute(userId) {
    return axios.get(`${URL_API_USER}/v1/signature?filter[user][eq]=${userId}`, {
      withCredentials: true,
    });
  }

  dataProtectionContractComplete(userId) {
    return axios.get(`${URL_API_USER}/v1/complete?filter[user][eq]=${userId}`, {
      withCredentials: true,
    });
  }

  getCloudFileById(fileId) {
    return axios.get(`${URL_API_CLOUD}/v1/files/${fileId}?service=user`, {
      withCredentials: true,
    });
  }

  getAutoresponders(userId) {
    return axios.get(
      `${URL_API_USER}/v1/autoresponder?filter[autoresponder][and][user][eq]=${userId}&size=500`,
      {
        withCredentials: true,
      },
    );
  }

  createAutoresponder(data) {
    const params = createAutoresponderDataPrepare(data);
    return axios.post(`${URL_API_USER}/v1/autoresponder/`, params, {
      withCredentials: true,
    });
  }

  editAutoresponder(data, id) {
    const params = createAutoresponderDataPrepare(data);
    return axios.patch(`${URL_API_USER}/v1/autoresponder/${id}`, params, {
      withCredentials: true,
    });
  }

  deleteAutoresponder(id) {
    return axios.delete(`${URL_API_USER}/v1/autoresponder/${id}`, {
      withCredentials: true,
    });
  }

  getPaymentGateways(userId) {
    return axios.get(
      `${URL_API_USER}/v1/payment?filter[payment][and][user][eq]=${userId}&size=500`,
      {
        withCredentials: true,
      },
    );
  }

  createPaymentGateway(data) {
    const params = createPaymentGatewayDataPrepare(data);
    return axios.post(`${URL_API_USER}/v1/payment/`, params, {
      withCredentials: true,
    });
  }

  editPaymentGateway(data, id) {
    const params = createPaymentGatewayDataPrepare(data);
    return axios.patch(`${URL_API_USER}/v1/payment/${id}`, params, {
      withCredentials: true,
    });
  }

  deletePaymentGateway(id) {
    return axios.delete(`${URL_API_USER}/v1/payment/${id}`, {
      withCredentials: true,
    });
  }

  getDomains(userId) {
    const notDeleted = '&filter[domain][and][status][neq]=5';
    return axios.get(
      `${URL_API_USER}/v1/domain?filter[domain][and][user][eq]=${userId}${notDeleted}&size=500&checkCanDelete=1&sort=dt-create`,
      {
        withCredentials: true,
      },
    );
  }

  getDomainById(domainId) {
    return axios.get(
      `${URL_API_USER}/v1/domain/${domainId}`,
      {
        withCredentials: true,
      },
    );
  }

  createDomain(data, userId) {
    const params = createDomainPrepare(data, userId);
    return axios.post(`${URL_API_USER}/v1/domain/`, params, {
      withCredentials: true,
    });
  }

  deleteDomain(id) {
    return axios.delete(`${URL_API_USER}/v1/domain/${id}`, {
      withCredentials: true,
    });
  }

  changeDomainSSLStatus(id, status) {
    const urlPart = status ? 'enable-ssl' : 'disable-ssl';
    return axios.get(`${URL_API_USER}/v1/domain/${id}/${urlPart}`, {
      withCredentials: true,
    });
  }

  searchDomain(domain) {
    return axios.get(`${URL_API_USER}/v1/domain/domain-search?domain=${domain}`, {
      withCredentials: true,
    });
  }

  checkDomain(domain) {
    return axios.get(`${URL_API_USER}/v1/domain/domain-check?domain=${domain}`, {
      withCredentials: true,
    });
  }

  getEmailForwarding(domainId) {
    return axios.get(`${URL_API_USER}/v1/email-forwarding?filter[domain][eq]=${domainId}`, {
      withCredentials: true,
    });
  }

  saveEmailForwarding(domainId, forwardTo) {
    const params = {
      data: {
        type: 'email-forwarding',
        attributes: {
          'forward-to': forwardTo,
        },
        relationships: {
          domain: {
            data: {
              type: 'domain',
              id: domainId,
            },
          },
        },
      },
    };

    return axios.post(`${URL_API_USER}/v1/email-forwarding/`, params, {
      withCredentials: true,
    });
  }

  updateEmailForwarding(emailForwardingId, forwardTo) {
    const params = {
      data: {
        type: 'email-forwarding',
        attributes: {
          'forward-to': forwardTo,
        },
      },
    };

    return axios.patch(`${URL_API_USER}/v1/email-forwarding/${emailForwardingId}`, params, {
      withCredentials: true,
    });
  }

  getTrackingCodes(userId) {
    return axios.get(
      `${URL_API_USER}/v1/tracking?filter[payment][and][user][eq]=${userId}`,
      {
        withCredentials: true,
      },
    );
  }

  createTrackingCode(data, userId) {
    const params = createTrackingCodeDataPrepare(data, userId);
    return axios.post(`${URL_API_USER}/v1/tracking/`, params, {
      withCredentials: true,
    });
  }

  editTrackingCode(data, id, userId) {
    const params = createTrackingCodeDataPrepare(data, userId);
    return axios.patch(`${URL_API_USER}/v1/tracking/${id}`, params, {
      withCredentials: true,
    });
  }

  deleteTrackingCode(id) {
    return axios.delete(`${URL_API_USER}/v1/tracking/${id}`, {
      withCredentials: true,
    });
  }

  getFunnelsAndPages(userId) {
    return axios.get(
      `${URL_API_FUNNELS}/v1/funnels/?filter[funnels.user][and][id][eq]=${userId}&page[size]=9999&filter[funnels][and][archive][neq]=1`,
      {
        withCredentials: true,
      },
    );
  }

  getOptions() {
    return axios.get(`${URL_API_USER}/v1/options/`, {
      withCredentials: true,
    });
  }

  getServices() {
    return axios.get(`${URL_API_USER}/v1/services/`, {
      withCredentials: true,
    });
  }

  getManagers(userId) {
    return axios.get(
      `${URL_API_USER}/v1/managers/?include=slave&filter[user][eq]=${userId}`,
      {
        withCredentials: true,
      },
    );
  }

  getManagerById(id) {
    return axios.get(`${URL_API_USER}/v1/managers/${id}?include=slave,options`, {
      withCredentials: true,
    });
  }

  createManager(data, userId) {
    const params = createManagerDataPrepare(data, userId);
    return axios.post(`${URL_API_USER}/v1/managers/`, params, {
      withCredentials: true,
    });
  }

  editManager(data, userId, id) {
    const params = createManagerDataPrepare(data, userId);
    return axios.patch(`${URL_API_USER}/v1/managers/${id}`, params, {
      withCredentials: true,
    });
  }

  deleteManager(id) {
    return axios.delete(`${URL_API_USER}/v1/managers/${id}`, {
      withCredentials: true,
    });
  }

  getCustomerTypeDivisions(userId) {
    return axios.get(
      `${URL_API_CONTACTS}/v1/customer-type-divisions/?filter[user][eq]=${userId}`,
      {
        withCredentials: true,
      },
    );
  }

  removeCustomerTypeDivision(divisionId) {
    return axios.delete(
      `${URL_API_CONTACTS}/v1/customer-type-divisions/${divisionId}`,
      {
        withCredentials: true,
      },
    );
  }

  createCustomerTypeDivision(data, userId) {
    const params = createCustomerTypeDivisionPrepare(data, userId);
    return axios.post(`${URL_API_CONTACTS}/v1/customer-type-divisions`, params, {
      withCredentials: true,
    });
  }

  changeCustomerTypeDivision(data, userId, divisionId) {
    const params = createCustomerTypeDivisionPrepare(data, userId);
    return axios.patch(
      `${URL_API_CONTACTS}/v1/customer-type-divisions/${divisionId}`,
      params,
      {
        withCredentials: true,
      },
    );
  }
}

const createAutoresponderDataPrepare = (data) => {
  const somedata = (() => {
    const r = {};
    data.user ? (r.user = data.user) : '';
    data.password ? (r.password = data.password) : '';
    data.api_key ? (r.api_key = data.api_key) : '';
    data.api_url ? (r.api_url = data.api_url) : '';
    return r;
  })();

  const result = {
    data: {
      attributes: {
        name: data.name,
        type: data.provider,
        data: somedata,
        active: data.active,
      },
      relationships: {
        user: {
          data: {
            type: 'user',
            id: data.userId,
          },
        },
      },
      type: 'autoresponder',
    },
  };

  return result;
};

const createPaymentGatewayDataPrepare = (data) => {
  const somedata = (() => {
    const r = {};
    data.api_key ? (r.api_key = data.api_key) : '';
    data.secret ? (r.secret = data.secret) : '';
    return r;
  })();

  if (data.test_mode !== undefined) {
    somedata.test_mode = data.test_mode;
  }

  const result = {
    data: {
      attributes: {
        name: data.name,
        type: data.provider,
        data: somedata,
        active: data.active,
      },
      relationships: {
        user: {
          data: {
            type: 'user',
            id: data.userId,
          },
        },
      },
      type: 'payment',
    },
  };

  return result;
};

const createTrackingCodeDataPrepare = (data, userId) => {
  const meta = {};
  meta['tracking-funnel'] = data.funnels;
  delete data.funnels;
  const result = {
    data: {
      attributes: {
        ...data,
      },
      relationships: {
        user: {
          data: {
            type: 'user',
            id: userId,
          },
        },
      },
      type: 'tracking',
    },
  };
  result.data.meta = meta;
  return result;
};

const createDomainPrepare = (data, userId) => {
  const result = {
    data: {
      attributes: {
        type: data.type,
        name: data.domain,
        active: data.active,
        page: data.indexPageId,
        page404: data.page404Id,
      },
      relationships: {
        user: {
          data: {
            type: 'user',
            id: userId,
          },
        },
      },
      type: 'domain',
    },
  };
  return result;
};

const createCustomerTypeDivisionPrepare = (data, userId) => {
  const result = {
    data: {
      attributes: {
        name: data.name,
        'value-from': parseInt(data['value-from']),
        'value-to': parseInt(data['value-to']),
      },
      relationships: {
        user: {
          data: {
            type: 'user',
            id: userId,
          },
        },
      },
      type: 'customer-type-divisions',
    },
  };
  return result;
};
const createManagerDataPrepare = (data, userId) => {
  const result = {
    data: {
      attributes: {
        email: data.email,
        description: data.description,
      },
      relationships: {
        user: {
          data: {
            type: 'user',
            id: userId,
          },
        },
        services: {
          data: data.services,
        },
        options: {
          data: data.options,
        },
      },
      type: 'manager',
    },
  };
  return result;
};

const apiSettings = new ApiSettings();
export default apiSettings;
