import { TYPE_EXTERNAL } from '@/enums/domain-statuses';
import apiSettings from '@/services/apiSettings';

export const LOAD_DOMAINS = 'LOAD_DOMAINS';
export const SET_DOMAINS = 'SET_DOMAINS';
export const DOMAINS = 'DOMAINS';
export const ACTIVATE_SSL = 'ACTIVATE_SSL';
export const SEARCH_DOMAINS = 'SEARCH_DOMAINS';
export const SET_SEARCHED_DOMAINS = 'SET_SEARCHED_DOMAINS';
export const SEARCHED_DOMAINS = 'SEARCHED_DOMAINS';
export const CHECK_DOMAIN = 'CHECK_DOMAIN';
export const BUY_DOMAIN = 'BUY_DOMAIN';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SEARCH_TEXT = 'SEARCH_TEXT';
export const RESET_BUY = 'RESET_BUY';
export const SET_ERROR = 'SET_ERROR';
export const ERROR = 'ERROR';

export default {
  namespaced: true,

  state: {
    domains: [],
    searchedDomains: [],
    searchText: '',
    error: null,
  },

  getters: {
    [DOMAINS]: (state) => state.domains,
    [SEARCHED_DOMAINS]: (state) => state.searchedDomains,
    [SEARCH_TEXT]: (state) => state.searchText,
    [ERROR]: (state) => state.error,
  },

  mutations: {
    [SET_DOMAINS]: (state, value) => {
      state.domains = value;
    },
    [SET_SEARCHED_DOMAINS]: (state, value) => {
      state.searchedDomains = value;
    },
    [SET_SEARCH_TEXT]: (state, value) => {
      state.searchText = value;
    },
    [RESET_BUY]: (state) => {
      state.searchedDomains = [];
      state.searchText = '';
    },
    [SET_ERROR]: (state, value) => {
      state.error = value;
    },
  },

  actions: {
    [LOAD_DOMAINS]: async ({ commit, rootState }) => {
      let domains = [];
      const response = await apiSettings.getDomains(rootState.auth.currentUserId);
      if (response && response.data.data) {
        domains = response.data.data.map(({ id, attributes }) => {
          const {
            active, name, type, status, canDelete, ds24url,
            'ssl-status': sslStatus,
          } = attributes;
          return {
            id,
            active,
            name,
            type,
            canDelete,
            status,
            sslStatus,
            ds24url,
          };
        });
      }
      commit(SET_DOMAINS, domains);
      commit(RESET_BUY);
    },
    [ACTIVATE_SSL]: async ({ state, commit }, id) => {
      const { status, data } = await apiSettings.changeDomainSSLStatus(id, true);
      if (status === 200) {
        const domain = state.domains.find((dom) => dom.id === id);
        if (domain) {
          domain.sslStatus = 3;
        }
      } else {
        commit(SET_ERROR, data);
      }
    },
    [SEARCH_DOMAINS]: async ({ commit }, domainName) => {
      let domains = [];
      const result = await apiSettings.searchDomain(domainName);
      if (result.status === 200) {
        domains = result.data.data.map((dom) => ({
          ...dom,
          cheked: false,
          price: '',
          available: null,
        }));
        commit(SET_SEARCHED_DOMAINS, domains);
      }
    },
    [CHECK_DOMAIN]: async ({ state }, checkDomainName) => {
      const domain = state.searchedDomains.find((dom) => dom.domain === checkDomainName);
      domain.cheked = true;
      const result = await apiSettings.checkDomain(domain.domain);
      if (result.status === 200) {
        const { available, price } = result.data.data;
        domain.available = available;
        domain.price = price;
      }
      domain.cheked = false;
    },
    [BUY_DOMAIN]: async ({ state, commit, rootState }, buyDomainName) => {
      const existingDomain = state.domains.find(
        (dom) => dom.name === buyDomainName && dom.status === 6,
      );
      if (existingDomain) {
        return {
          domain: existingDomain.name,
          iframeUrl: existingDomain.ds24url,
          custom: existingDomain.id,
        };
      }
      const domain = state.searchedDomains.find((dom) => dom.domain === buyDomainName);
      const data = {
        type: TYPE_EXTERNAL, // new domain
        domain: domain.domain,
      };
      const result = await apiSettings.createDomain(data, rootState.auth.currentUserId);
      if (result.status === 200) {
        const { attributes } = result.data.data;
        domain.iframeUrl = attributes.ds24url;
        domain.custom = attributes.id;
        return domain;
      }
      commit(SET_ERROR, result.data);
      return {};
    },
  },
};
