import createAxiosInstance from '../helpers/createAxiosInstance';

export default class Api {
  constructor({ baseURL, prefix }) {
    this.prefix = prefix;
    this.http = createAxiosInstance({ baseURL });
  }

  get basePath() {
    return this.prefix || '';
  }

  getList(params = null) {
    return this.http.get(this.basePath, { params });
  }

  getOne(id, params = {}) {
    return this.http.get(`${this.basePath}/${id}`, { params });
  }

  create(body) {
    return this.http.post(this.basePath, body);
  }

  update(id, body) {
    return this.http.patch(`${this.basePath}/${id}`, body);
  }

  delete(id) {
    return this.http.delete(`${this.basePath}/${id}`);
  }

  custom(method = 'get', path, body = {}, options) {
    if (method === 'get') {
      return this.http[method](`${this.basePath}/${path}`, { params: body, ...options });
    }
    return this.http[method](`${this.basePath}/${path}`, body);
  }
}
