import injector from 'vue-inject';

function localSettings() {
  const { localStorage } = window;
  const save = (param, value) => {
    localStorage.setItem(param, value);
  };

  const get = (param) => localStorage.getItem(param);

  return {
    save,
    get,
  };
}
injector.service('localSettings', [], localSettings);
