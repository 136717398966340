import injector from 'vue-inject';
import axios from 'axios';

const userAxiosInstance = axios.create();
userAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const apiUser = injector.get('apiUser');
    if (error.config.validateStatus) {
      if (error.config.validateStatus(error.response.status)) {
        return error.response;
      }
    } else {
      console.log('response.status in error = ', error);
      if (error.response && error.response.status === 404) {
        return error.response;
      }
      if (error.response && error.response.status === 400) {
        return error.response;
      }
      if (error.response && error.response.status === 401) {
        apiUser.goToSsoPage();
      }
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

injector.constant('userAxios', userAxiosInstance);
