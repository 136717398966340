export const STEP_TYPE_B = 1; // Bridge
export const STEP_TYPE_O = 2; // Optinpage / Webinar Registration
export const STEP_TYPE_REP = 3; // WebinarReply
export const STEP_TYPE_C = 4; // Confirmation
export const STEP_TYPE_T = 5; // Optin Thank You
export const STEP_TYPE_W = 6; // WebinarRoomCard
export const STEP_TYPE_OF = 8; // Order Form
export const STEP_TYPE_BR = 10; // Bridge Redirect
export const STEP_TYPE_TOF = 12; // Order Thank You
export const STEP_TYPE_OOF = 13; // Optin after Orderform (Form)
export const STEP_TYPE_OFW = 14; // Optin after free Webinar (Form)
export const STEP_TYPE_S = 15; // Sales
export const STEP_TYPE_US = 16; // Upsell
export const STEP_TYPE_DS = 17; // Downsell
export const STEP_TYPE_R = 18; // Webinar Registration
export const STEP_TYPE_WC = 19; // Webinar Countdown
export const STEP_TYPE_F = 20; // Canditatureform
export const STEP_TYPE_TFW = 21; // Thank You page (after webinar)
export const STEP_TYPE_I = 22; // Imprint
export const STEP_TYPE_P = 23; // Privacy policy
export const PAGE_TYPE_REDIRECTPAGE = 24; // redirectpage
export const PAGE_TYPE_LIVESHOW = 25; // liveshow
export const PAGE_TYPE_SUPPORT = 26; // support
export const PAGE_TYPE_HOMEPAGE = 27; // homepage
export const PAGE_TYPE_VIDEO_OVERVIEW = 28; // video-overview
export const PAGE_TYPE_DATA_PROTECTION = 29; // data-protection
export const PAGE_TYPE_PRODUCT_OVERVIEW = 30; // product-overview
export const PAGE_TYPE_IMPRINT = 31; // imprint
export const PAGE_TYPE_ARTICLE = 32; // article
export const PAGE_TYPE_TESTIMONIALS = 33; // testimonials
export const PAGE_TYPE_GALLERY = 34; // gallery
export const PAGE_TYPE_404 = 35; // 404
export const PAGE_TYPE_BLANK_PAGE = 36; // blank-page
export const PAGE_TYPE_OTHERS = 37; // others
// notification
export const EMAIL_TYPE_NOTIFICATION = 38;
export const EMAIL_TYPE_ACADEMY_NEW_USER = 39;
export const EMAIL_TYPE_ACADEMY_EXISTING_USER = 40;
export const EMAIL_TYPE_ACADEMY_PASSWORD_RESTORE = 41;
export const EMAIL_TYPE_ACADEMY_PASSWORD_CHANGE = 42;
export const EMAIL_TYPE_ACADEMY_PASSWORD_RESTORED = 43;
export const EMAIL_TYPE_ACADEMY_PASSWORD_CHANGED = 44;
export const EMAIL_TYPE_ACADEMY_2F_AUTH_CODE = 45;
export const ACADEMY_TYPE_LOGIN_PAGE = 46;
export const ACADEMY_TYPE_REGISTRATION_PAGE = 47;
export const ACADEMY_TYPE_RESTORE_PASSWORD_PAGE = 48;
export const ACADEMY_TYPE_DASHBOARD = 49;
export const ACADEMY_TYPE_COURSES_PAGE = 50;
export const ACADEMY_TYPE_COURSE_PAGE = 51;
export const ACADEMY_TYPE_LESSON_PAGE = 52;
export const ACADEMY_TYPE_COURSE_COMPLETE = 53;
export const ACADEMY_TYPE_IMPRESSUM = 54;
export const ACADEMY_TYPE_PRIVACY_POLICY = 55;
export const ACADEMY_TYPE_SALES_PAGE = 56;
export const ACADEMY_TYPE_PROFILE = 57;
export const ACADEMY_TYPE_404 = 58;
export const ACADEMY_TYPE_PACKAGE_PRICING_TABLE = 59;
export const ACADEMY_TYPE_THANK_YOU_PAGE = 60;
export const ACADEMY_TYPE_QUIZ_COMPLETE = 61;
export const TEMPLATES_TYPE_HEADER = 62;
export const TEMPLATES_TYPE_FOOTER = 63;
// Webinar Email Registration
export const EMAIL_TYPE_WEBINAR_REGISTRATION = 64;
export const EMAIL_TYPE_WEBINAR_REMINDER = 65;
export const EMAIL_TYPE_ACADEMY_MEMBER_REGISTRATION = 66;
export const ACADEMY_TYPE_LESSON_COMPLETE = 68;
export const ACADEMY_TYPE_CHANGE_PASSWORD_PAGE = 69;
export const EMAIL_TYPE_CONFORMATION = 70;
export const ACADEMY_TYPE_QUIZ_PAGE = 71;
export const TEMPLATES_TYPE_POPUPS = 72;
export const EMAIL_TYPE_ACADEMY_ACTION_REGISTRATION = 73;
export const EMAIL_TYPE_ACADEMY_ACTION_PURCHASE = 74;
export const EMAIL_TYPE_ACADEMY_ACTION_FINISHED_COURSE = 75;
export const EMAIL_TYPE_ACADEMY_ACTION_FINISHED_MODULE = 76;
export const EMAIL_TYPE_ACADEMY_ACTION_FINISHED_LESSON = 77;
export const EMAIL_TYPE_ACADEMY_ACTION_FINISHED_QUIZ = 78;
export const WEBINAR_TYPE_LOGIN_PAGE = 80;
