import { URL_API_USER } from '@dmant/ez-env-common';
import Api from './Api';

class PaymentGatewaysApi extends Api {
  constructor() {
    super({
      baseURL: URL_API_USER,
      prefix: '/v1/payment',
    });
  }

  getList(ownerId) {
    const params = {
      'filter[payment][and][user][eq]': ownerId,
      'fields[payment]': 'id,name,active,type,data',
      checkCanDelete: 1,
      size: 500,
    };

    return super.getList(params);
  }

  create(form, ownerId) {
    const data = this.prepareRequestBody(form, ownerId);
    return super.create({ data });
  }

  update(autoresponderId, form) {
    const data = this.prepareRequestBody(form);
    return super.update(autoresponderId, { data });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareRequestBody(form, ownerId) {
    return {
      attributes: form,
      relationships: {
        user: {
          data: {
            type: 'user',
            id: ownerId,
          },
        },
      },
      type: 'payment',
    };
  }
}

export default new PaymentGatewaysApi();
