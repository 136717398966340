import apiSettings from '@/services/apiSettings';
import timeAndLang from './settingsState/timeAndLang';
import autoresponderProviders from './settingsState/autoresponderProviders';
import paymentGatewayProviders from './settingsState/paymentGatewayProviders';
// import get from 'lodash/get';

export default {
  namespaced: true,

  state: {
    title: 'settings.text',
    ...timeAndLang,
    autoresponderProviders,
    paymentGatewayProviders,
    tariff: {},
  },

  mutations: {
    SET_TARIFF(state, data) {
      state.tariff = {
        current: data.current ? data.current.data.attributes['name-en'].toUpperCase() : null,
        next: data.next ? data.next.data.meta : null,
      };
    },
  },

  actions: {
    async GET_TARIFF({ commit }, userId) {
      const result = await apiSettings.getTariff(userId);
      commit('SET_TARIFF', result.data.data);
    },
  },

  getters: {},
};
