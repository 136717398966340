export default {
  path: '/wizard',
  component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/layout.vue'),
  children: [
    {
      path: '',
      name: 'wizardWelcome',
      component: () => import(/* webpackChunkName: "wizardWelcome" */ '@/pages/wizard/welcome.vue'),
    },
    {
      path: 'autoresponders',
      name: 'wizardAutoresponders',
      component: () => import(/* webpackChunkName: "wizardAutoresponders" */ '@/pages/wizard/steps/autoresponders.vue'),
    },
    {
      path: 'payment',
      name: 'wizardPayment',
      component: () => import(/* webpackChunkName: "wizardAutoresponders" */ '@/pages/wizard/steps/payment.vue'),
    },
    {
      path: 'privacy',
      name: 'wizardPrivacy',
      component: () => import(/* webpackChunkName: "wizardAutoresponders" */ '@/pages/wizard/steps/privacy.vue'),
    },
    {
      path: 'domains',
      name: 'wizardDomains',
      props: true,
      component: () => import(/* webpackChunkName: "wizardAutoresponders" */ '@/pages/wizard/steps/domains.vue'),
    },
  ],
};
