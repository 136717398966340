import { URL_API_USER } from '@dmant/ez-env-common';
import Api from './Api';

class Smtp extends Api {
  constructor() {
    super({
      baseURL: URL_API_USER,
      prefix: '/v1/smtp',
    });
  }

  getList(ownerId) {
    const params = {
      'filter[smtp][and][user][eq]': ownerId,
      'fields[smtp]': 'id,name,active,from-email,smtp-server',
      size: 500,
      sort: 'name',
    };

    return super.getList(params);
  }

  create(form, ownerId) {
    const data = this.prepareRequestBody(form, ownerId);
    return super.create({ data });
  }

  update(entityId, form) {
    const data = this.prepareRequestBody(form);
    return super.update(entityId, { data });
  }

  test(entityId, email) {
    const data = {
      attributes: {
        email,
      },
      type: 'smtp',
    };
    return super.custom('post', `test/${entityId}`, { data });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareRequestBody(form, ownerId) {
    return {
      attributes: form,
      relationships: {
        user: {
          data: {
            type: 'user',
            id: ownerId,
          },
        },
      },
      type: 'smtp',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async getUsage(id) {
    console.assert(id, 'id is required');
    const params = {
      'filter[smtp][and][id][eq]': id,
      'fields[smtp]': '',
      checkCanDelete: true,
      size: 1,
    };

    const result = await super.getList(params);
    return result?.[0]?.meta?.using || [];
  }
}

export default new Smtp();
