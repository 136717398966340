import { URL_API_USER, URL_AUTH } from '@dmant/ez-env-common';
import injector from 'vue-inject';
import promiseMemoize from 'promise-memoize';

const CACHE_RESTORE_VERIFY_CODE_TTL = 60 * 1000;
const CACHE_RESTORE_VERIFY_CODE_FAIL_TTL = 5 * 1000;

function apiUser(axios) {
  const goToSsoPage = (continuePage = window.location.href) => {
    const goToUrl = continuePage
      ? `${URL_AUTH}?continue=${continuePage}`
      : URL_AUTH;
    window.location.href = goToUrl;
    // console.log("Want go to SSO");
  };
  const login = async (name, password, rememberMe) => {
    const data = new FormData();
    data.append('email', name);
    data.append('password', password);
    if (rememberMe) data.append('rememberme', rememberMe);

    let rawResponse = {};
    let result = {
      status: 'fail',
      details: 'Unknown error',
    };

    await axios
      .post(`${URL_API_USER}/v1/login`, data, {
        withCredentials: true,
      })
      .then((response) => {
        rawResponse = response;
      })
      .catch((error) => {
        if (error.response) {
          rawResponse = error.response;
        }
      });

    switch (rawResponse.status) {
      case 200:
        result = {
          status: 'ok',
          details: { ...rawResponse.data.data, manage: true },
        };
        break;

      default:
        result = {
          status: 'fail',
          details: rawResponse.data,
        };
        break;
    }

    return result;
  };
  const validateSession = async () => {
    let rawResponse = {};
    let result = {
      status: 'fail',
      details: 'Unknown error',
    };

    await axios
      .get(`${URL_API_USER}/v1/identity`, {
        withCredentials: true,
      })
      .then((response) => {
        rawResponse = response;
      })
      .catch((error) => {
        if (error.response) {
          rawResponse = error.response;
        }
      });

    switch (rawResponse.status) {
      case 200:
        result = {
          status: 'ok',
          details: { ...rawResponse.data.data, manage: true },
        };
        result.details.attributes.wizard = true;
        break;

      default:
        result = {
          status: 'fail',
          details: rawResponse.data,
        };
        break;
    }

    return result;
  };
  const logout = async () => {
    await axios.get(`${URL_API_USER}/v1/logout`, {
      withCredentials: true,
    });
  };

  const restorePassword = async (email) => {
    const data = new FormData();
    data.append('email', email);

    let rawResponse = {};
    let result = {
      status: 'fail',
      details: 'Unknown error',
    };

    await axios
      .post(`${URL_API_USER}/v1/restore`, data, {
        withCredentials: false,
      })
      .then((response) => {
        rawResponse = response;
      })
      .catch((error) => {
        if (error.response) {
          rawResponse = error.response;
        }
      });

    switch (rawResponse.status) {
      case 200:
        result = {
          status: 'ok',
          details: { ...rawResponse.data.data, manage: true },
        };
        break;

      default:
        result = {
          status: 'fail',
          details: rawResponse.data,
        };
        break;
    }

    return result;
  };

  const restoreVerifyCode = async ({ email, code }) => {
    // try {
    const { data } = await axios({
      url: `${URL_API_USER}/v1/restore/verify`,
      params: { email, code },
      withCredentials: false,
      validateStatus: (status) => status === 200,
    });
    return data.data;
  };

  const changepass = async ({ email, password, code }) => {
    const data = new FormData();
    data.append('email', email);
    data.append('code', code);
    data.append('password', password);

    let rawResponse = {};
    let result = {
      status: 'fail',
      details: 'Unknown error',
    };

    await axios
      .post(`${URL_API_USER}/v1/changepass`, data, {
        withCredentials: false,
      })
      .then((response) => {
        rawResponse = response;
      })
      .catch((error) => {
        if (error.response) {
          rawResponse = error.response;
        }
      });

    switch (rawResponse.status) {
      case 200:
        result = {
          status: 'ok',
          details: { ...rawResponse.data.data, manage: true },
        };
        break;

      default:
        result = {
          status: 'fail',
          details: rawResponse.data,
        };
        break;
    }

    return result;
  };

  return {
    goToSsoPage,
    login,
    validateSession,
    logout,
    restorePassword,
    restoreVerifyCode: promiseMemoize(restoreVerifyCode, {
      maxAge: CACHE_RESTORE_VERIFY_CODE_TTL,
      maxErrorAge: CACHE_RESTORE_VERIFY_CODE_FAIL_TTL,
      resolve: 'json',
    }),
    changepass,
  };
}
injector.service(
  'apiUser',
  ['userAxios'],
  apiUser,
);
