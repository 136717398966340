import Vue from 'vue';
import './plugins/sentry';
import axios from 'axios';
import Notifications from 'vue-notification';
import { refreshToken } from '@dmant/ez-auth-common';
import liveChat from '@dmant/ez-livechat-common';
import Ability from '@dmant/ez-lib/src/ability/install';
import QuickStartVideoPlugin from '@dmant/ez-lib/src/quick-start-video';
import Popup from '@dmant/ez-ui-components/src/components/popup';
import PopupContainer from '@dmant/ez-ui-components/src/components/popup-container';
import App from './App.vue';
import router from './router';
import injector from './services';
import './components';
import store from './store';
import i18n from './i18n';
import validation from './validateConfig';

validation.init(i18n.locale);

Vue.use(Popup);
Vue.use(PopupContainer);
Vue.use(liveChat);
Vue.use(Ability);
Vue.use(Notifications);
Vue.use(QuickStartVideoPlugin, { router, store });

injector.constant('i18n', i18n);

Vue.config.productionTip = false;

const vueInstance = new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

injector.constant('vueInstance', vueInstance);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const apiUser = injector.get('apiUser');
    if (error.config.validateStatus) {
      if (error.config.validateStatus(error.response.status)) {
        return error.response;
      }
      if (error.response.status === 403) {
        store.commit('auth/SET_EXPIRED');
        return error.response;
      }
    } else {
      console.log('response.status in error = ', error);
      if (error.response && error.response.status === 404) {
        return error.response;
      }
      if (error.response && error.response.status === 403) {
        store.commit('auth/SET_EXPIRED');
        return error.response;
      }
      if (error.response && error.response.status === 400) {
        return error.response;
      }
      if (error.response && error.response.status === 401) {
        apiUser.goToSsoPage();
      }
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

refreshToken();

/* eslint-disable no-undef */
console.log(`Build: ${BUILD_INFO.BUILDDATETIME}, ${BUILD_INFO.VERSION}, ${BUILD_INFO.COMMITHASH}, ${BUILD_INFO.BRANCH}`);
