export default {
  languageOptions: [{
    locale: 'en_EN',
    text: 'English',
  }, {
    locale: 'de_DE',
    text: 'Deutsch',
  }],
  currencyOptions: [{
    index: 1,
    text: 'EUR',
    symbol: '€',
  },
  {
    index: 2,
    text: 'USD',
    symbol: '$',
  },
  {
    index: 3,
    text: 'CHF',
    symbol: '₣',
  }],
};
