import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const locales = require.context(
  './locales',
  true,
  /\b\w\w_\w\w\.json$/,
);

const defaultLocale = process.env.VUE_APP_I18N_LOCALE || 'en_EN';

const supportedLocales = locales.keys()
  .map((s) => s.replace(/^[^\w]+|\.[^.]+$/g, '')); // remove ./ at start locale path and .json at the end

function loadLocaleMessages() {
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const formatToLowerDash = (str) => str
  .replace(/[^\w]+/g, '_') // replace - to _
  .toLowerCase();

const formatToFirstPart = (str) => formatToLowerDash(str).split('_')[0];

const getNavigatorLanguage = () => {
  if (navigator.languages?.length) {
    const defaultFormated = formatToFirstPart(defaultLocale);
    const preferredLang = navigator.languages
      .find((lang) => formatToFirstPart(lang) === defaultFormated);
    if (preferredLang) {
      return preferredLang;
    }
    const exactlyLocaleLang = navigator.languages.find((lang) => {
      const formatedLang = formatToLowerDash(lang);
      return supportedLocales.some((locale) => formatToLowerDash(locale) === formatedLang);
    });
    if (exactlyLocaleLang) {
      return exactlyLocaleLang;
    }
    const fuzyLang = navigator.languages.find((lang) => {
      const formatedLang = formatToFirstPart(lang);
      return supportedLocales.some((locale) => formatToFirstPart(locale) === formatedLang);
    });
    if (fuzyLang) {
      return fuzyLang;
    }
  }

  return navigator.userLanguage
    || navigator.language
    || navigator.browserLanguage
    || defaultLocale;
};
function getLocale() {
  try {
    const lang = getNavigatorLanguage();

    const langFormated = formatToLowerDash(lang);
    const exactlyLocale = supportedLocales.find((s) => langFormated === formatToLowerDash(s));
    if (exactlyLocale) {
      return exactlyLocale;
    }

    const langPart = formatToFirstPart(lang);
    const fuzylocale = supportedLocales.find((s) => langPart === formatToFirstPart(s));
    if (fuzylocale) {
      return fuzylocale;
    }
  } catch (e) {
    console.warn(e);
  }
  return defaultLocale;
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en_EN',
  messages: loadLocaleMessages(),
});
