/**
 * Common global components
 */
import Vue from 'vue';

import Container from '@dmant/ez-ui-components/src/components/container';
import Content from '@dmant/ez-ui-components/src/components/content';
import Row from '@dmant/ez-ui-components/src/components/row';
import Col from '@dmant/ez-ui-components/src/components/col';
import Layout from '@dmant/ez-ui-components/src/components/layout';
import Header from '@dmant/ez-ui-components/src/components/header';
import HeaderBar from '@dmant/ez-ui-components/src/components/header-bar';
import Footer from '@dmant/ez-ui-components/src/components/footer';
import Logotype from '@dmant/ez-ui-components/src/components/logotype';
import Button from '@dmant/ez-ui-components/src/components/button';
import ButtonGroup from '@dmant/ez-ui-components/src/components/button-group';
import Input from '@dmant/ez-ui-components/src/components/input';
import Dropdown from '@dmant/ez-ui-components/src/components/dropdown';
import FormItem from '@dmant/ez-ui-components/src/components/form-item';
import DropdownItem from '@dmant/ez-ui-components/src/components/dropdown-item';
import DropdownMenu from '@dmant/ez-ui-components/src/components/dropdown-menu';
import DropdownUser from '@dmant/ez-ui-components/src/components/dropdown-user';
import Checkbox from '@dmant/ez-ui-components/src/components/checkbox';
import Social from '@dmant/ez-ui-components/src/components/social';
import Link from '@dmant/ez-ui-components/src/components/link';
import Icons from '@dmant/ez-ui-components/src/components/icons';
import Icon from '@dmant/ez-ui-components/src/components/icon';
import Notification from '@dmant/ez-ui-components/src/components/notification';
import Notice from '@dmant/ez-ui-components/src/components/notice';
import SidebarNavigation from '@dmant/ez-ui-components/src/components/sidebar-navigation';
import Select from '@dmant/ez-ui-components/src/components/select';
import Message from '@dmant/ez-ui-components/src/components/message';
import Preloader from '@dmant/ez-ui-components/src/components/preloader';
import Tabs from '@dmant/ez-ui-components/src/components/tabs';
import Switch from '@dmant/ez-ui-components/src/components/switch';
import Form from '@dmant/ez-ui-components/src/components/form';
import Breadcrumbs from '@dmant/ez-ui-components/src/components/breadcrumbs';
import SubHeader from '@dmant/ez-ui-components/src/components/sub-header';
import DropArea from '@dmant/ez-ui-components/src/components/drop-area';
import Card from '@dmant/ez-ui-components/src/components/card';
import FilterControl from '@dmant/ez-ui-components/src/components/filter-control';
import Dialog from '@dmant/ez-ui-components/src/components/dialog';
import PopupContainer from '@dmant/ez-ui-components/src/components/popup-container';
import InfoBox from '@dmant/ez-ui-components/src/components/info-box';
import Panel from '@dmant/ez-ui-components/src/components/panel';

Vue.use(Panel);
Vue.use(Container);
Vue.use(Content);
Vue.use(Row);
Vue.use(Col);
Vue.use(Layout);
Vue.use(Header);
Vue.use(HeaderBar);
Vue.use(Footer);
Vue.use(Logotype);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(DropdownUser);
Vue.use(Checkbox);
Vue.use(Social);
Vue.use(Link);
Vue.use(Icons);
Vue.use(Icon);
Vue.use(Notification);
Vue.use(Notice);
Vue.use(SidebarNavigation);
Vue.use(Select);
Vue.use(Preloader);
Vue.use(InfoBox);
Vue.use(Message);
Vue.use(Switch);
Vue.use(Tabs);

// for cloud popup
Vue.use(Form);
Vue.use(Breadcrumbs);
Vue.use(SubHeader);
Vue.use(Content);
Vue.use(DropArea);
Vue.use(Card);
Vue.use(FilterControl);
Vue.use(Dialog);
Vue.use(PopupContainer);
