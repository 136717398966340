import Vue from 'vue';
import injector from 'vue-inject';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import router from '@/router';
import i18n from '@/i18n';
import apiSettings from '@/services/apiSettings';
import validation from '@/validateConfig';
import updateAbility from '@dmant/ez-lib/src/ability/update';

const notifyLoggedIn = (user) => {
  if (user) {
    let displayName = user.name;
    if (!displayName && user) {
      const firstName = user['first-name'] || '';
      const lastName = user['last-name'] || '';
      displayName = `${firstName} ${lastName}`.trim();
    }
    if (displayName) {
      const content = i18n.t('Logged in as', { name: displayName });
      Vue.notify({
        data: {
          type: 'primary',
          content,
        },
      });
    }
  }
};

export default {
  namespaced: true,
  state: {
    isWizard: false,
    isAuthorized: false,
    mainUser: null,
    currentUserId: null,
    availableUsers: [],
    isExpired: false,
    avatarUrls: {},
  },
  mutations: {
    SET_WIZARD_STATUS(state, status) {
      state.isWizard = status;
    },
    SET_MAIN_USER(state, user) {
      state.mainUser = user;
      state.currentUserId = user.id;
      if (user.id !== state.mainUser.id) {
        router.push({ params: { userId: user.id } });
      }
    },
    UPDATE_MAIN_USER(state, user) {
      state.mainUser.attributes['last-name'] = user['last-name'];
      state.mainUser.attributes['first-name'] = user['first-name'];
      state.mainUser.attributes.email = user.email;
      state.mainUser.attributes.locale = user.locale;
      state.mainUser.attributes.country = user.country;
      i18n.locale = user.locale;
      validation.changeLocale(user.locale);
    },
    SET_CURRENT_USER_ID(state, userId) {
      state.currentUserId = userId;
    },
    SET_AUTHORIZED_STATUS(state, status) {
      state.isAuthorized = status;
    },
    SET_AVAILABLE_USERS(state, users) {
      state.availableUsers = users;
    },
    SET_EXPIRED: (state) => {
      state.isExpired = true;
    },
    UNSET_EXPIRED: (state) => {
      state.isExpired = false;
    },
    SET_USER_AVATARS: (state, urls) => {
      state.avatarUrls = urls;
    },
  },
  actions: {
    async VALIDATE_SESSION({
      dispatch,
    }) {
      const apiUser = injector.get('apiUser');
      const result = await apiUser.validateSession();
      if (result.status === 'ok') {
        await dispatch('SUCCESSFUL_AUTH', result.details);
      } else {
        apiUser.goToSsoPage();
      }
    },

    async LOGOUT({
      dispatch,
    }) {
      const apiUser = injector.get('apiUser');
      await apiUser.logout();
      dispatch('FAIL_AUTH');
    },

    async SUCCESSFUL_AUTH({ commit, dispatch }, user) {
      const i18nInstance = injector.get('i18n');
      const localSettings = injector.get('localSettings');

      const userLocale = user.attributes.locale;
      i18nInstance.locale = userLocale;
      validation.changeLocale(userLocale);
      const fullUsersList = [user];
      let currentUser = user;

      const savedUser = localSettings.get('lastSelectedUser');
      if (savedUser) {
        const availableUser = fullUsersList.find((v) => v.id === savedUser);
        if (availableUser) currentUser = availableUser;
      }

      commit('SET_MAIN_USER', user);
      commit('SET_WIZARD_STATUS', user.attributes.wizard);
      commit('SET_CURRENT_USER_ID', currentUser.id);
      commit('SET_AUTHORIZED_STATUS', true);
      commit('SET_AVAILABLE_USERS', fullUsersList);
      dispatch('USER_AVATARS', fullUsersList);
      await updateAbility(currentUser.id);

      notifyLoggedIn(user.attributes);
    },

    FAIL_AUTH({
      commit,
    }) {
      commit('SET_MAIN_USER', null);
      commit('SET_CURRENT_USER_ID', null);
      commit('SET_AUTHORIZED_STATUS', false);
      updateAbility(null);
    },

    async USER_AVATARS({ commit }, users) {
      const getFile = async (cloudId) => {
        if (cloudId) {
          try {
            const { data } = await apiSettings.getCloudFileById(cloudId);
            return data?.data?.attributes;
          } catch (error) {
            console.log(error);
          }
        }
        return undefined;
      };
      const files = await Promise.all(users.map(({ attributes }) => getFile(attributes.avatar)));
      const result = files.filter(Boolean).reduce((obj, { id, url }) => {
        obj[id] = url;
        return obj;
      }, {});
      commit('SET_USER_AVATARS', result);
    },

    async CHANGE_USER({ commit, state }, userId) {
      const localSettings = injector.get('localSettings');
      const selectedUser = state.availableUsers.find((v) => v.id === userId);
      if (selectedUser) {
        const routerPushObject = { path: '/', query: {} };
        if (selectedUser.id !== state.mainUser.id) {
          routerPushObject.params = { userId: selectedUser.id };
          delete routerPushObject.path;
          updateAbility(userId);
        }
        localSettings.save('lastSelectedUser', userId);
        commit('SET_CURRENT_USER_ID', userId);
        router.push(routerPushObject);
      }
      // console.log("CHANGE_USER ", user);
    },
    async WIZARD_FINISH({ commit, state }) {
      const status = true;
      await apiSettings.setAccountInfo(
        state.currentUserId,
        { wizard: status },
      );
      commit('SET_WIZARD_STATUS', status);
      state.mainUser.attributes.wizard = status;
    },
  },
  getters: {
    isWizardFinish: (state) => state.isWizard,
    isUserSessionValid(state) {
      return state.isAuthorized;
    },
    availableUsersPrettyFormat(state) {
      const fullUsersListPretty = state.availableUsers.map((u) => {
        const userInPrettyFormat = {
          id: u.id,
          name: `${u.attributes['first-name']} ${u.attributes['last-name']}`,
          email: u.attributes.email,
          image: state.avatarUrls[u.attributes.avatar] || '',
        };
        return userInPrettyFormat;
      });
      return fullUsersListPretty;
    },
    currentUserFullName(state) {
      const { attributes = {} } = state.mainUser;
      return `${attributes['first-name']} ${attributes['last-name']}`;
    },
    dateUserTz: (state) => (value, options = {}) => {
      const { 'time-zone': tz } = state.mainUser?.attributes || {};
      const { format = 'YYYY-MM-DD HH:mm:ss' } = options;
      const date = tz
        ? moment.utc(value).tz(tz)
        : moment.utc(value);
      return date.isValid()
        ? date.format(format)
        : null;
    },
    isAdmin(state) {
      return (state.mainUser?.meta?.roles || []).includes('admin');
    },
  },
};
