import { ACTIONS, SUBJECTS } from '@/enums/permissions';

export default [{
  path: 'smtp',
  name: 'smtp',
  component: () => import(/* webpackChunkName: "smtp" */ '@/pages/settings/setup/Smtp/SmtpIndex.vue'),
  redirect: { name: 'smtp-list' },
  children: [
    {
      path: '',
      name: 'smtp-list',
      meta: {
        checkAccess: [{
          action: ACTIONS.view,
          subject: SUBJECTS.UserSmtp,
        }],
      },
      component: () => import(/* webpackChunkName: "smtp" */ '@/pages/settings/setup/Smtp/List.vue'),
    },
    {
      path: ':id/edit',
      name: 'smtp-edit',
      props: true,
      meta: {
        checkAccess: [{
          action: ACTIONS.edit,
          subject: SUBJECTS.UserSmtp,
        }],
      },
      component: () => import(/* webpackChunkName: "smtp" */ '@/pages/settings/setup/Smtp/Edit.vue'),
    },
    {
      path: 'create',
      name: 'smtp-create',
      meta: {
        checkAccess: [{
          action: ACTIONS.create,
          subject: SUBJECTS.UserSmtp,
        }],
      },
      component: () => import(/* webpackChunkName: "smtp" */ '@/pages/settings/setup/Smtp/Edit.vue'),
    },
  ],
}];
