export default [
  {
    name: 'DS24',
    urlName: 'ds24',
    text: 'payment_ds24_explanations',
    fields: ['api_key'],
  },
  {
    name: 'Elopage',
    urlName: 'elopage',
    text: 'payment_elopage explanations',
    fields: ['api_key', 'secret', 'test_mode'],
  },
];
