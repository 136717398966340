import { extend, localize } from 'vee-validate';
import {
  // eslint-disable-next-line camelcase
  required, email, confirmed, min, numeric, alpha_num,
} from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { mapLocales } from '@/enums/locales';

const url = (value) => {
  let inputUrl = value;
  const urlPattern = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  if (inputUrl.substring(0, 7) !== 'http://' && inputUrl.substring(0, 8) !== 'https://') {
    inputUrl = `http://${inputUrl}`;
  }
  return urlPattern.test(inputUrl);
};
const phone = (value) => {
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber && phoneNumber.isValid();
};

const customMessages = {
  en: {
    messages: {
      url: (field) => `${field} is invalid`,
    },
  },
  de: {
    messages: {
      url: (field) => `${field} ist ungültig`,
    },
  },
};

function changeLocale(lang) {
  const locale = String(lang).split('_')[0].toLowerCase();
  localize(mapLocales[locale]);
}

function init(locale) {
  localize({ en, de });
  localize(customMessages);
  changeLocale(locale);
  extend('required', required);
  extend('url', url);
  extend('email', email);
  extend('confirmed', confirmed);
  extend('min', min);
  extend('numeric', numeric);
  extend('alpha_num', alpha_num);
  extend('phone', phone);
}

export default {
  changeLocale,
  init,
  url,
};
