import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import settings from './settings';
import wizardPages from './wizardPages';
import authPages from './authPages';

Vue.use(Router);

const routerInstance = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || '/',
  routes: [
    authPages,
    {
      path: '/',
      name: 'checkAccess',
      component: () => import('@dmant/ez-lib/src/ability/checkAccess.vue'),
      meta: { requiresAuth: true },
      props: {
        subject: 'UserServices',
        forbiddenComponent: () => import(/* webpackChunkName: "403" */ '@/pages/403.vue'),
      },
      children: [
        settings,
        wizardPages,
      ],
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "page404" */ '../pages/404.vue'),
    },
  ],
});

routerInstance.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta?.requiresAuth)) {
    if (store.getters['auth/isUserSessionValid']) {
      next();
    } else {
      store.dispatch('auth/VALIDATE_SESSION').then(() => {
        if (store.getters['auth/isWizardFinish'] || to.name === 'domain-thanks') {
          next();
        } else {
          next({ name: 'wizardWelcome' });
        }
      });
    }
  } else {
    next();
  }
});

routerInstance.afterEach((to) => {
  if (to.matched.some((record) => record.meta?.hideLiveChat)) {
    // Vue.prototype.$LiveChat.hide();
  } else {
    // Vue.prototype.$LiveChat.show();
  }
});

export default routerInstance;
