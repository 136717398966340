import Funnels from './Funnels';
import Academies from './Academies';
import Trackings from './Trackings';
import Domains from './Domains';
import EmailAutoresponders from './EmailAutoresponders';
import EmailForwarding from './EmailForwarding';
import Smtp from './Smtp';
import PaymentGateways from './PaymentGateways';

export default {
  Academies,
  Funnels,
  Trackings,
  Domains,
  EmailAutoresponders,
  EmailForwarding,
  Smtp,
  PaymentGateways,
};
