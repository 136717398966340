import {
  URL_CLOUD,
  URL_CONTACTS,
  URL_FUNNELS,
  URL_MEMBER,
  URL_PLAYER,
  URL_WEBINAR,
  URL_IMPRESSUM,
  URL_TERMS,
  URL_CANCELLATION,
  URL_COURSE,
} from '@dmant/ez-env-common';
import i18n from '@/i18n';
import getFaqUrl from '@/helpers/getFaqUrl';

export default {
  namespaced: true,

  state: {
    servicesList: [
      {
        id: 'funnels',
        name: 'Funnels',
        url: URL_FUNNELS,
        icon: 'filter',
        testAttr: 'to-funnels',
      },
      {
        id: 'cloud',
        name: 'Cloud',
        url: URL_CLOUD,
        icon: 'cloud',
        testAttr: 'to-cloud',
      },
      {
        id: 'player',
        name: 'Player',
        url: URL_PLAYER,
        icon: 'play',
        testAttr: 'to-player',
      },
      {
        id: 'webinar',
        name: 'Webinar',
        url: URL_WEBINAR,
        icon: 'video',
        testAttr: 'to-webinar',
      },
      {
        id: 'contacts',
        name: 'Contacts',
        url: URL_CONTACTS,
        icon: 'id-card',
        testAttr: 'to-contacts',
      },
      {
        id: 'academy',
        name: 'Member',
        url: URL_MEMBER,
        icon: 'chalkboard',
        testAttr: 'to-member',
      },
      {
        id: 'course',
        name: 'Course',
        url: URL_COURSE,
        icon: 'study',
        testAttr: 'to-course',
      },
    ],
    headerBarStats: [
      {
        name: 'header.topNav.websites',
        key: 'websites',
      },
      {
        name: 'header.topNav.contacts',
        key: 'contacts',
      },
      {
        name: 'header.topNav.visitors',
        key: 'visitors',
      },
    ],
    footer: {
      nav: [
        {
          name: 'imprint',
          text: 'footer.imprint',
          href: URL_IMPRESSUM,
        },
        {
          name: 'terms',
          text: 'footer.terms',
          href: URL_TERMS,
        },
        {
          name: 'cancellation',
          text: 'footer.cancellation',
          href: URL_CANCELLATION,
        },
      ],
      social: [
        { name: 'facebook', href: 'https://go.ezfunnels.com/facebook' },
        { name: 'instagram', href: 'https://go.ezfunnels.com/instagram' },
        { name: 'youtube', href: 'https://go.ezfunnels.com/youtube' },
        // { name: <String>[, href: <String> ] }
        // names: facebook, instagram, twitter, google-plus, linkedin, youtube
      ],
    },
  },

  getters: {
    navLinks: ({ footer }) => {
      const links = [
        ...footer.nav,
        {
          name: 'faq',
          text: 'footer.faq',
          href: getFaqUrl(),
        },
      ].map((item) => ({
        ...item,
        text: i18n.t(item.text),
      }));
      return links;
    },
  },
};
